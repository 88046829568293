<template>
  <div>
    <!--/ add comments -->
    <div class="w-100 cmt-posts d-flex align-items-end justify-content-center px-1" >
      <div
        class="w-100 border commentBox main-post"  v-if="(index < 3 && topThree)|| (index === currentPostIndex && showCommentBox)" :class="{ 'focused': isFocused }" 
        @mousedown="isFocused = true" 
        @blur="isFocused = false" >
        <CommentBox placeholder="Share your thoughts..." className="comment-box pt-25" :id="'comment-field-' + index"
          :toolbar="false" :mentionList="employeeListOptions" width="100%" @update:value="handleCommentUpdate" 
           
            />
          <b-button variant="primary" v-if="show" @click="addComment(post, index)"
          class="blog-btn-block search-post m-0" :disabled="commentSubmitClicked" pill>
          Comment
        </b-button>
      </div>
    </div>

    <div class="bg-reply p-1" v-if="post.latest_comments.length">
      <!-- comments -->
      <div v-for="(comment, ind) in visibleComments" :key="'comment-' + ind" class="reply-post mb-1 mt-50">
        <div :class="comment.user.hashid == userData.hashid ? 'cmd-main cmd-owner' : 'cmd-main' ">
          <div class="d-flex align-items-start w-100 over-cmd">
            <b-avatar target="_blank" :href="`/profile/${comment.user.hashid}`" :src="comment.user.avatar" size="30" class="mr-75 mt-0" />
            <div v-if="!editComments.includes(comment.hashid)" class="profile-user-info w-100 right-cmt mt-0">
              <div class="d-flex align-items-start justify-content-between">
                <div class="profile-user-info d-flex align-items-center">
                  <h6 class="mb-0">
                    {{ comment.user.name }}
                  </h6>
                  <span class="mx-25">
                    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
                      <circle cx="1.25" cy="2" r="1.25" fill="#667085"/>
                    </svg>
                  </span>
                  <small class="cmt-desgination">
                  {{ comment.user.designation }}
                </small>
                </div>
                <div class="d-flex align-items-center">
                  <span class="small mr-25">
                    {{ comment.created_at }}
                  </span>
                  <!-- More Options Dots -->
                  <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                    no-caret v-if="comment.user.hashid == userData.hashid">
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted"/>
                    </template>
                    <b-dropdown-item class="btn-sm p-0 text-muted" v-if="comment.user.hashid == userData.hashid" @click="
                      editComments.pop(), editComments.push(comment.hashid)
                      ">
                      <feather-icon icon="EditIcon" size="18" class="mr-50"/><span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="btn-sm p-0 text-danger"
                      @click="deleteComment(comment.hashid, post.hashid, index)"
                      v-if="comment.user.hashid == userData.hashid">
                      <feather-icon icon="TrashIcon" size="18" class="mr-50"/>
                      <span class="">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- End More Options Dots -->
                  <!-- <div class="d-none d-lg-block">
              <b-link
                @click="like(comment.hashid, index)"
                class="text-body btn btn-icon rounded-circle p-50"
                v-b-tooltip.html.hover="
                  'Liked by ' + getNames(comment.likes)
                "
              >
                <feather-icon
                  icon="HeartIcon"
                  size="18"
                  class="mr-25"
                  :class="
                    comment.youLiked
                      ? 'profile-likes'
                      : 'profile-icon'
                  "
                />
                <span
                  v-if="comment.likes_count"
                  class="text-muted align-middle"
                  >{{ kFormatter(comment.likes_count) }}</span
                >
              </b-link>
              <b-link
                v-if="comment.user.hashid == userData.hashid"
                class="btn btn-icon text-primary rounded-circle p-50"
                @click="
                  editComments.pop(),
                    editComments.push(comment.hashid)
                "
              >
                <feather-icon icon="EditIcon" size="18" 
                v-b-tooltip.hover.v-default
                title="Comment Edit"/>
              </b-link>
              <b-link
                v-if="comment.user.hashid == userData.hashid"
                class="text-danger btn btn-icon rounded-circle p-50"
                @click="
                  deleteComment(comment.hashid, post.hashid, index)
                "
              >
                <feather-icon icon="TrashIcon" size="18" 
                v-b-tooltip.hover.v-danger
                title="Delete Comment"/>
              </b-link>
              <b-link
                class="btn btn-icon text-primary rounded-circle p-50"
                @click="addReply(comment, index)"
              >
                <feather-icon icon="CornerUpLeftIcon" size="18" 
                v-b-tooltip.hover.v-default
                title="Reply to Comment" />
              </b-link>
            </div> -->
                </div>
              </div>
              <p v-html="comment.comment" class="mb-0 mt-75 community-reply"></p>
              <div class="d-none mb-lg-0 d-lg-none">
                <b-link @click="like(comment.hashid, index)"
                  class="text-body btn btn-icon rounded-circle pl-0 pt-50 pb-50 pr-50">
                  <feather-icon icon="HeartIcon" size="18" class="mr-25"
                    :class="comment.youLiked ? 'profile-likes' : 'profile-icon'" />
                  <!-- <span v-if="comment.likes_count" class="text-muted align-middle">{{ kFormatter(comment.likes_count)
                  }}</span> -->
                </b-link>
                <b-link v-if="comment.user.hashid == userData.hashid"
                  class="btn btn-icon rounded-circle p-50"
                  @click="editComments.pop(), editComments.push(comment.hashid)">
                  <feather-icon icon="EditIcon" size="18" v-b-tooltip.hover.v-default title="Edit Comment" />
                </b-link>
                <b-link v-if="comment.user.hashid == userData.hashid" class="text-danger btn btn-icon rounded-circle p-50"
                  @click="deleteComment(comment.hashid, post.hashid, index)">
                  <feather-icon icon="TrashIcon" size="18" v-b-tooltip.hover.v-danger title="Delete Comment" />
                </b-link>
                <b-link class="btn btn-icon rounded-circle p-50" @click="addReply(comment, index)">
                  <feather-icon icon="CornerUpLeftIcon" size="18" v-b-tooltip.hover.v-default title="Repy to Comment" />
                </b-link>
              </div>

              <!-- <div>
          <b-link class="nav-link" @click="addReply(comment, index)">
            <feather-icon
              icon="CornerUpLeftIcon"
              size="18"
              class="mr-25"
            />
            Reply
          </b-link>
        </div> -->
            </div>
            <div class="w-100 cmt-posts d-flex align-items-end justify-content-centent"
              v-else="editComments.includes(comment.hashid)">
              <div
                class="border mb-0 commentBox w-100">
                <CommentBox placeholder="Share your thoughts..." className="w-100 comment-box pt-25"
                  :id="'comment-field-' + comment.hashid" :value="comment.comment" :toolbar="false"
                  :mentionList="employeeListOptions" />
                  <div class="cmd-editbtn">
                    <b-button variant="outline-secondary" v-if="editComments.includes(comment.hashid)"
                      class="blog-btn-block search-post m-0" @click="editComments.pop()">
                      Cancel
                    </b-button>
                    <b-button variant="primary" @click="editComment(comment, post.hashid, index)" pill
                      class="blog-btn-block search-post m-0" :disabled="commentSubmitClicked">
                      Save
                    </b-button>
                  </div>
              </div>
            </div>
          </div>
          <div class="option-reply d-flex align-items-center w-100 pl-3">
            <b-row class="like-button mx-0 align-items-center">
              <div class="action-group d-flex align-items-center">
                <b-link class="d-flex align-items-center text-muted text-nowrap post-likes">
                  <div class="likes">
                    <!-- <feather-icon
                    icon="HeartIcon"
                    class="mr-50 text-primary profile-icon"
                    size="18"
                    title="Like"
                    @click="like(comment.hashid, index, 'like')"
                  /> -->
                  <span 
                      title="Like" 
                      class="active-like" 
                      v-if="comment.youLiked && comment.likeType"
                  >

                  <span 
                      v-if="comment.likeType === 'like'" 
                      title="Like" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment.hashid, index, 'like')" 
                  >
                      Like
                  </span>

                  <span 
                      v-if="comment.likeType === 'clap'" 
                      title="Appreciate" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment.hashid, index, 'clap')" 
                  >
                      Appreciate
                  </span>

                  <span 
                      v-if="comment.likeType === 'love'" 
                      title="Love" 
                      class="active-like" 
                      style="color: #D92D20"
                      @click="like(comment.hashid, index, 'love')" 
                  >
                      Love
                  </span>

                  <span 
                      v-if="comment.likeType === 'celebrate'" 
                      title="Celebrate" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment.hashid, index, 'celebrate')" 
                  >
                     Celebrate
                  </span>

                  <span 
                      v-if="comment.likeType === 'idea'" 
                      title="Insightful" 
                      class="active-like" 
                      style="color: #0E7090"
                      @click="like(comment.hashid, index, 'idea')" 
                  >
                  Insightful
                  </span>

                  <span 
                      v-if="comment.likeType === 'smile'" 
                      title="Laugh" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment.hashid, index, 'smile')" 
                  >
                      Laugh
                  </span>

                      <!-- <b-img 
                          v-if="comment.likeType === 'like'" 
                          :src="require('@/assets/images/icons/like/like.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="like(comment.hashid, index, 'like')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'clap'" 
                          :src="require('@/assets/images/icons/like/appreciate.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="like(comment.hashid, index, 'clap')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'love'" 
                          :src="require('@/assets/images/icons/like/love.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="like(comment.hashid, index, 'love')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'celebrate'" 
                          :src="require('@/assets/images/icons/like/celebrate.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="like(comment.hashid, index, 'celebrate')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'idea'" 
                          :src="require('@/assets/images/icons/like/insightful.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="like(comment.hashid, index, 'idea')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'smile'" 
                          :src="require('@/assets/images/icons/like/laugh.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="like(comment.hashid, index,'smile')"
                      /> -->
                  </span>

                  <span 
                      title="Like" 
                      class="active-like" 
                      @click="like(comment.hashid, index, 'like')" 
                      v-else
                  >
                      Like
                  </span>

                    <div class="emoji-reactions">
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'like')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Like">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/like.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'clap')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Appreciate">
                        <b-img :src="require('@/assets/images/icons/like/appreciate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'love')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Love">
                        <b-img :src="require('@/assets/images/icons/like/love.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/love.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'celebrate')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Celebrate">
                        <b-img :src="require('@/assets/images/icons/like/celebrate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'idea')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Insightful">
                        <b-img :src="require('@/assets/images/icons/like/insightful.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'smile')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Laugh">
                        <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                    </div>
                  </div>
                  <div class="likes" v-if="comment.youLiked && comment.likeType">
                    <div class="emoji-reactions">
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'like')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Like">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/like.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'clap')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Appreciate">
                        <b-img :src="require('@/assets/images/icons/like/appreciate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'love')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Love">
                        <b-img :src="require('@/assets/images/icons/like/love.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/love.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'celebrate')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Celebrate">
                        <b-img :src="require('@/assets/images/icons/like/celebrate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'idea')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Insightful">
                        <b-img :src="require('@/assets/images/icons/like/insightful.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'smile')" v-b-tooltip.hover.v-default v-b-tooltip.top title="Laugh">
                        <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                    </div>
                  </div>

                  <!-- <span class="text-primary" v-if="comment.likes_count" v-b-modal="'show-likes-' + comment.hashid">{{
                    kFormatter(comment.likes_count) }}</span> -->
                </b-link>
                <!-- <b-modal :id="'show-likes-' + comment.hashid" hide-footer modal-class="modal-primary modal-likes"
                  scrollable title="Reactions" class="text-danger">
                  <b-list-group v-if="comment.likes && comment.likes.length">
                    <b-list-group-item v-for="like in comment.likes" :key="'user-' + like.user.hashid"
                      class="d-flex align-items-center">
                      <b-avatar target="_blank" :href="`/profile/${like.user.hashid}`":src="like.user.avatar" size="24" class="mr-1" />
                      {{ like.user.name }}
                      <div class="ml-auto" v-if="like.type == 'like'">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'clap'">
                        <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                          " width="24" title="Appreciate"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'love'">
                        <b-img :src="require('@/assets/images/icons/like/love.svg')
                          " width="24" title="Love"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'celebrate'">
                        <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                          " width="24" title="Celebrate"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'idea'">
                        <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                          " width="24" title="Insightful"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'smile'">
                        <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="24" title="Laugh"></b-img>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card-text v-else-if="comment.likes && comment.likes.length == 0">
                    <div class="item error text-center my-2 empty-data">
                      <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                        style="background-color: transparent" />
                      <h4> OH NO!</h4>
                      <p class="d-block mb-1">No Records Found!</p>
                    </div>
                  </b-card-text>
                  <b-card-text v-else-if="comment.likes == null">
                    <div class="d-flex align-items-center">
                      <strong>Loading...</strong>
                      <b-spinner class="ml-auto" variant="primary" />
                    </div>
                  </b-card-text>
                </b-modal> -->

                <b-modal :id="'show-likes-' + comment.hashid" hide-footer hide-header modal-class="modal-likes" class="reactions-popup" @show="groupCommentLikes(comment)" #default="{ hide }" centered>
                  <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="hide" />  
                  <!-- Static New Design -->
                    <b-tabs v-if="commentLikes && commentLikes.all && commentLikes.all.length" class="overall-reaction">
                      <b-tab id="user-reactions">
                          <template #title>
                            <span class="d-flex align-items-center"><span>All</span> <span class="count">({{commentLikes.all.length}})</span></span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="like in commentLikes.all">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img v-if="like.type == 'like'" :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'clap'" :src="require('@/assets/images/icons/like/appreciate.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'love'" :src="require('@/assets/images/icons/like/love.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'celebrate'" :src="require('@/assets/images/icons/like/celebrate.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'idea'" :src="require('@/assets/images/icons/like/insightful.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'laugh'" :src="require('@/assets/images/icons/like/laugh.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ like.user.name }}
                                    </h6>
                                    <small>
                                      {{ like.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="like-reaction" v-if="commentLikes.like && commentLikes.like.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/like.gif')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.like.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="like in commentLikes.like">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ like.user.name }}
                                    </h6>
                                    <small>
                                      {{ like.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="appriceate-reaction" v-if="commentLikes.clap && commentLikes.clap.length" >
                        <template #title>
                          <span class="d-flex align-items-center">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.gif')
                              " width="20"></b-img>
                            <span class="count">{{commentLikes.clap.length}}</span>
                          </span>
                        </template>
                        <b-card-text>
                          <ul class="list-unstyled">
                            <li v-for="clap in commentLikes.clap">
                              <div class="d-flex align-items-center">
                                <div class="position-relative note-reaction">
                                  <b-avatar  target="_blank" :href="`/profile/${clap.user.hashid}`" :src="clap.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                  <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="7" class="small-icon"></b-img>
                                </div>
                                <div class="profile-user-info">
                                  <h6 class="mb-0">
                                    {{ clap.user.name }}
                                  </h6>
                                  <small>
                                    {{ clap.user.designation }}
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-tab>
                      <b-tab id="laugh-reaction" v-if="commentLikes.laugh && commentLikes.laugh.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.laugh.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="laugh in commentLikes.laugh">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${laugh.user.hashid}`" :src="laugh.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ laugh.user.name }}
                                    </h6>
                                    <small>
                                      {{ laugh.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="love-reaction" v-if="commentLikes.love && commentLikes.love.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/love.gif')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.love.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="love in commentLikes.love">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${love.user.hashid}`" :src="love.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/love.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ love.user.name }}
                                    </h6>
                                    <small>
                                      {{ love.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="celebrate-reaction" v-if="commentLikes.celebrate && commentLikes.celebrate.length" >
                        <template #title>
                          <span class="d-flex align-items-center">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.gif')
                              " width="20"></b-img>
                            <span class="count">{{commentLikes.celebrate.length}}</span>
                          </span>
                        </template>
                        <b-card-text>
                          <ul class="list-unstyled">
                            <li v-for="celebrate in commentLikes.celebrate">
                              <div class="d-flex align-items-center">
                                <div class="position-relative note-reaction">
                                  <b-avatar  target="_blank" :href="`/profile/${celebrate.user.hashid}`" :src="celebrate.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                  <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="7" class="small-icon"></b-img>
                                </div>
                                <div class="profile-user-info">
                                  <h6 class="mb-0">
                                    {{ celebrate.user.name }}
                                  </h6>
                                  <small>
                                    {{ celebrate.user.designation }}
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-tab>
                      <b-tab id="insightful-reaction" v-if="commentLikes.idea && commentLikes.idea.length">
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/insightful.gif')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.idea.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="idea in commentLikes.idea">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${idea.user.hashid}`" :src="idea.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ idea.user.name }}
                                    </h6>
                                    <small>
                                      {{ idea.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                    </b-tabs>
                     <!-- End Static New Design -->
                    <!-- <b-list-group v-if="postLikes && postLikes.length">
                      <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                        class="d-flex align-items-center">
                        <b-avatar target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" size="24" class="mr-1" />
                        <b-link
                          :to="{
                            name: 'employee-profile',
                            params: { id: like.user.hashid },
                          }"
                          class="font-weight-bold d-inline-block text-nowrap"
                          target="_blank"
                        >
                          {{ like.user.name }}
                        </b-link>
                        <div class="ml-auto" v-if="like.type == 'like'">
                          <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'clap'">
                          <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                            " width="24" title="Appreciate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'love'">
                          <b-img :src="require('@/assets/images/icons/like/love.svg')
                            " width="24" title="Love"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'celebrate'">
                          <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                            " width="24" title="Celebrate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'idea'">
                          <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                            " width="24" title="Insightful"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'laugh'">
                          <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="24" title="Laugh"></b-img>
                        </div>
                      </b-list-group-item>
                    </b-list-group> -->
                    <b-card-text v-else-if="commentLikes && commentLikes.length == 0">
                      <div class="item error text-center my-2 empty-data">
                        <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                          style="background-color: transparent" />
                        <h4> OH NO!</h4>
                        <p class="d-block mb-1">No Records Found!</p>
                      </div>
                    </b-card-text>
                    <b-card-text v-else-if="commentLikes == null">
                      <div class="d-flex align-items-center">
                        <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                          style="background-color: transparent" />
                        <h4> Loading </h4>
                      </div>
                    </b-card-text>
                  </b-modal>


                <!-- <div class="d-none d-lg-flex mobile-avatar align-items-center">                    
                    <b-avatar-group size="18" class="ml-75">
                      <div class="d-flex reaction-details"  v-for="(like, i) in getMosReactions(comment.likes)">
                        <b-img v-if="like == 'love'" :src="require('@/assets/images/icons/like/love-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'idea'" :src="require('@/assets/images/icons/like/idea-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'clap'" :src="require('@/assets/images/icons/like/clap-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'like'" :src="require('@/assets/images/icons/like/thumb-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'support'" :src="require('@/assets/images/icons/like/handlove-emji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'smile'" :src="require('@/assets/images/icons/like/smile-emji.png')" width="18" height="18"/>
                      </div>
                      <span class="px-25"></span>
                      <b-avatar
                        v-for="(like, i) in comment.likes"
                        v-if="i < 3"
                        :key="'avatar-' + i"
                        v-b-tooltip.hover.bottom="like.user.name"
                        class="pull-up m-0"
                        :src="like.user.avatar"
                      />
                    </b-avatar-group>
                    <b-link
                      v-if="comment.likes_count > 3"
                      class="text-primary text-nowrap ml-25"
                      v-b-modal="'show-likes-' + comment.hashid"
                    >
                      +{{ comment.likes_count - 3 }} more
                    </b-link>
                  </div> -->
              </div>
              <div class="d-flex align-items-center" v-if="comment.likes && comment.likes.length">
              <!-- Likes Count -->
              <div class="likes mr-50">
                <div class="d-flex align-items-center gap-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none" class="mr-25">
                    <circle cx="1.25" cy="2" r="1.25" fill="#667085"/>
                  </svg>
                  <b-avatar-group size="20px" 
                  >
                      <b-avatar
                        v-for="(like, index) in getUniqueLikes(comment.likes).slice(0, 2)"
                        :key="index"
                        :src="getAvatarSrc(like.type)"
                        :title="capitalize(like.type)"
                        size="15"
                        variant="light"
                        v-b-modal="'show-likes-' + comment.hashid"

                      ></b-avatar>
                    </b-avatar-group>
                  <span
                      v-if="comment.likes_count > 0"
                      class="reactions-count"
                    >
                     {{ comment.likes_count }}
                  </span>
                </div>
              </div>
              <!-- Replies-->
              <!-- <b-link class="nav-link reply-count" @click="getReplyComments(itemId); show=!show">
                <feather-icon v-if="!show && comment.reply_count" icon="PlusIcon" size="12" />
                <feather-icon v-if="show && comment.reply_count"  icon="MinusIcon" size="12" />
                {{ comment.reply_count }} Replies
              </b-link> -->
            </div>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="2" height="15" viewBox="0 0 2 15" fill="none" class="line-separate mr-50">
                <path d="M1 0.5L1 14.5" stroke="#98A2B3" stroke-width="0.5" stroke-linecap="round" />
              </svg>
            </span>
              <div class="reply-action d-flex align-items-center mt-0">
                <b-link class="btn btn-icon rounded-circle p-0 small" @click="addReply(comment, index)">
                  Reply
                </b-link>
              </div>
            </b-row>
          </div>
        </div>
        <Replies v-if="comment.reply_count" :itemId="comment.hashid" :reply_count="comment.reply_count"
          :mentionList="employeeListOptions" />

        <!-- Reply and Like Section -->
        <div class="reply-cmd pl-3">
          <div class="replyto-cmd pb-1 my-1" v-if="comment_id &&
            comment_id == comment.hashid &&
            comment_post_index == index
            ">
            <div class="d-flex justify-content-end align-items-center">
              <!-- <div class="small">
                Reply to <span class="font-weight-bolder">{{ reply_to }}</span>
              </div> -->
              <!-- <b-link class="btn btn-icon rounded-circle p-50" @click="removeReply">
                <feather-icon icon="XIcon" size="18" v-b-tooltip.hover.v-default title="Cancel" />
              </b-link> -->
            </div>
            <!--/ add comments -->
            <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
              <div
                class="w-100 border commentBox main-post" :class="{ 'focused': isReplyFocused }" 
                @mousedown="isReplyFocused = true" >
                <CommentBox placeholder="Enter Your Reply" className="w-100 comment-box pt-25"
                  :id="'comment-field-' + comment_id" :toolbar="false" :mentionList="employeeListOptions"  @update:value="handleReplyCommentUpdate" />
                <b-button variant="primary"  v-if="replyShow"  @click="addComment(post, comment_id)"
                  class="blog-btn-block search-post m-0" :disabled="commentSubmitClicked" pill>
                  Comment
                </b-button>
              </div>
            </div>
          </div>

        </div>
        <!-- End Reply and Like Section -->
      </div>
      <!-- Expand and Collapse Command -->
      <b-link class="expand-cmds">
        <span class="more-cmd" v-if="showMoreCommentsButton && showComment!='all'" @click="loadMoreComments">More Comments</span>
        <!-- <span class="less-cmd">Less Comments</span> -->
      </b-link>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Replies from "./Replies.vue";
import $ from "jquery";
import { comment } from 'postcss';
import { bus } from "@/main.js";

Vue.use(VueCookies);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    QuillInput,
    Replies,
    CommentBox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    post: {
      required: true,
    },
    index: {
      required: true,
    },
    employeeListOptions: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
    postDataComment:{
      type:Array
    },
    showCommentBox: {
      type: Boolean,
      required: true
    },
    topThree:{
      type: Boolean,
      required: true
    },
    currentPostIndex: {
      required: true
    },
    showComment:{
      required: true
    },
    updatePostData: {
      type: Function,
    },


  },
  data() {
    return {
      show:'',
      replyShow:'',
      userData: this.$cookies.get("userData"),
      editComments: [],
      comment_id: null,
      comment_post_index: null,
      reply_to: null,
      commentSubmitClicked: false,
      groupedComments: [],
      commentLikes:null,
      currentCount: 2, 
      isFocused: false,
      isReplyFocused: false
    };
  },
  computed: {
    visibleComments() {
      if (this.showComment === 'latest') {
        return this.post.latest_comments.slice(0, this.currentCount);
      } else {
        return this.post.comments;
      }
    },
    showMoreCommentsButton() {
      if (this.post.comments_count > this.currentCount) {
        return true;
      }
      return false;
    }
  },
  mounted() { },
  methods: {
    kFormatter,

    groupCommentLikes(comment) {

      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get('post_hash_id')){
        this.showComment = "all"
      }
      else{
        this.showComment = "latest"
      }
      this.commentLikes = {};

      comment.likes.forEach(reaction => {
        if(this.commentLikes[reaction.type]){
          this.commentLikes[reaction.type].push(reaction);
        }else{
          this.commentLikes[reaction.type] = [reaction];
        }
      });

      this.commentLikes['all'] = comment.likes;
    },

    getUniqueLikes(likes) {
      const seenTypes = new Set();
      return likes.filter(like => {
        if (!seenTypes.has(like.type)) {
          seenTypes.add(like.type);
          return true;
        }
        return false;
      });
    },
    getAvatarSrc(type) {
      const icons = {
        like: require('@/assets/images/icons/like/like.svg'),
        clap: require('@/assets/images/icons/like/appreciate.svg'),
        love: require('@/assets/images/icons/like/love.svg'),
        celebrate: require('@/assets/images/icons/like/celebrate.svg'),
        idea: require('@/assets/images/icons/like/insightful.svg'),
        smile: require('@/assets/images/icons/like/laugh.svg')
      };
      return icons[type] || '';
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    handleCommentUpdate(val) {
      this.show = val;
      this.isFocused = val
      if(!this.show){
      this.isFocused = false 
      }
    },
    handleReplyCommentUpdate(val){
      this.replyShow = val;
      this.isReplyFocused = val
      if(!this.replyShow){
      this.isReplyFocused = false 
      }
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },

    loadMoreComments(){
      if (this.currentCount >= this.post.latest_comments.length) {
            this.$emit('view-post', this.post);
            bus.$emit('toggle-sidebar', true);
          
      } else {
        this.currentCount = Math.min(this.currentCount + 3, this.post.latest_comments.length);
      }
    },

    addReply(data, index) {
      const self = this;
      self.comment_id = data.hashid;
      self.reply_to = data.user.name;
      self.comment_post_index = index;
      document.getElementById("comment-field-" + index).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = null;
    },
    like(comment_id, index, type) {
      const self = this;
      let data = {};
      data.url = "comments/" + comment_id + "/like/" + type;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          if(this.showComment == 'latest'){
            this.$parent.updatePostArray(res.data.data, index);
          }
          else{
            this.$http
              .get(`/posts/${this.post.hashid}/getAllComments`)
              .then((res) => {
                self.post.comments = res.data.data;
              })
              .catch((error) => {
                console.log(error);
              });
            this.updatePostData(self.post)
          }
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "liking failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `liking failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          if(this.showComment == 'latest'){
            this.$parent.updatePostArray(res.data.data, index);
          }
          else{
            this.$http
              .get(`/posts/${postId}/getAllComments`)
              .then((res) => {
                this.post.comments = res.data.data;
              })
              .catch((error) => {
                console.log(error);
              });
            this.updatePostData(this.post)
          }
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment deleted successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"successful-info",
              hideProgressBar : true,
            });
        });
    },
    addComment(data, index) {
      this.commentSubmitClicked = true;
      const self = this;
      var comment = document.getElementById(`comment-field-${index}`).value;
      
      if (self.comment_id) {
        var comment = document.getElementById(
          `comment-field-${self.comment_id}`
        ).value;
      }
      if (comment === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Comment adding Failed",
        //     icon: "BellIcon",
        //     text: "Can't post empty comment",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Can't post empty comment",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: self.comment_id,
      };
      this.$http
        .post(`/posts/${data.hashid}/comments`, params)
        .then((res) => {
          self.reply_to = null;
          self.comment_id = null;
          if(this.showComment == 'latest'){
            this.$parent.updatePostArray(res.data.data, index);
          }
          else{
            this.$http
              .get(`/posts/${data.hashid}/getAllComments`)
              .then((res) => {
                self.post.comments = res.data.data;
              })
              .catch((error) => {
                console.log(error);
              });
            this.updatePostData(self.post)
          }
          document.getElementById(`comment-field-${index}`).value = "";
          localStorage.removeItem(`comment-field-${index}`)
          $(".ql-editor").html("");
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    editComment(comment, post_id, index) {
      this.commentSubmitClicked = true;
      const self = this;
      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          if(this.showComment == 'latest'){
            this.$parent.updatePostArray(res.data.data, index);
          }
          else{
            this.$http
              .get(`/posts/${post_id}/getAllComments`)
              .then((res) => {
                self.post.comments = res.data.data;
              })
              .catch((error) => {
                console.log(error);
              });
            this.updatePostData(self.post)
          }
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
        });
      return true;
    },
    getMosReactions(likes) {
      var reactions = {
        like: 0,
        love: 0,
        celebrate: 0,
        clap: 0,
        smile: 0,
        idea: 0,
      };

      likes.forEach((like) => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter((key) => reactions[key] !== 0);

      return top3;
    },
  },
};
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.commentBox {
  transition: all 0.3s ease; /* Smooth transition */
}

.commentBox .ql-snow.ql-toolbar {
  display: none !important; /* Hide the toolbar by default */
}

.commentBox.focused .ql-snow.ql-toolbar {
  display: flex !important; /* Show the toolbar when focused */
}

.commentBox.focused .new-comment-box {
  flex-direction: column;
  align-items: start;
}

.commentBox.focused .search-post {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: auto;
  right: 18px;
  width: auto;
  min-width: 85px;
}
</style>
