<template>
  <div class="cmtnest-reply my-75">
    <b-link class="nav-link reply-count" @click="getReplyComments(itemId)">
      <!-- <feather-icon v-if="!show" icon="PlusIcon" size="12" />
      <feather-icon v-if="show" icon="MinusIcon" size="12" /> -->
      {{ reply_count }} Replies
    </b-link>
    <div class="nest-reply my-75" v-if="show"
        v-for="(comment, index) in replies"
        :key="index">
      <div 
        :class="comment.user.hashid == userData.hashid ? 'nested-main cmd-owner' : 'nested-main'"
      >
      <div class="d-flex align-items-start w-100 over-cmd">
      <b-avatar :src="comment.user.avatar" size="30" class="mr-75" />
        <div class="profile-user-info w-100 right-cmt" v-if="!editComments.includes(comment.hashid)">
            <div class="d-flex align-items-start justify-content-between">
              <div class="profile-user-info d-flex align-items-center">
                <h6 class="mb-0">
                  {{ comment.user.name }}
                </h6>
                <span class="dot mx-25"></span>
                <small>
                  {{ comment.user.designation }}
                </small>
              </div>
              <div class="d-flex align-items-center">
                <span class="small">
                  {{ comment.created_at }}
                </span>
                <div class="d-block">
                  <!-- More Options Dots -->
                      <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0" no-caret  v-if="comment.user.hashid == userData.hashid">
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted"/>
                        </template>
                            <b-dropdown-item
                              class="btn-sm p-0 text-muted"
                              v-if="comment.user.hashid == userData.hashid"
                      @click="editComments.pop(), editComments.push(comment.hashid)"
                            >
                                <feather-icon icon="EditIcon" size="18" class="mr-50"/><span>Edit</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="comment.user.hashid == userData.hashid" class="btn-sm p-0 text-danger" @click="deleteComment(comment.hashid, comment.post_hashid, itemId)">
                            <feather-icon icon="TrashIcon" size="18" class="mr-50"/>
                              <span class="">Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                      <!-- End More Options Dots -->
                    <!-- <b-link
                        class="text-body text-body btn btn-icon rounded-circle  p-50"
                        v-b-tooltip.html.hover="'Liked by ' + getNames(comment.likes)"
                        @click="likeComment(comment, itemId)"
                    >
                        <feather-icon
                            icon="HeartIcon"
                            size="18"
                            :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                        />
                        <span
                            v-if="comment.likes.length"
                            class="text-muted align-middle"
                        >
                    {{ comment.likes.length }}
                  </span>
                    </b-link>
                    <b-link
                        v-if="comment.user.hashid == userData.hashid"
                        class="text-primary btn btn-icon rounded-circle  p-50"
                        @click="editComments.pop(), editComments.push(comment.hashid)"
                    >
                        <feather-icon icon="EditIcon" size="18" />
                    </b-link>
                    <b-link
                        v-if="comment.user.hashid == userData.hashid"
                        class="text-danger btn btn-icon rounded-circle p-50"
                        @click="
                    deleteComment(comment.hashid, comment.post_hashid, itemId)
                  "
                    >
                        <feather-icon icon="TrashIcon" size="18" class="mr-lg-25" />
                    </b-link> -->
                </div>
              </div>
            </div>
            <div class="mt-75 blog-description"  v-html="comment.comment"></div>
            <!-- Mobile Code -->
              <!-- <div class="d-flex d-lg-none">
                  <b-link
                      class="text-body text-body btn btn-icon rounded-circle pl-0  pt-50 pb-50 pr-50"
                      v-b-tooltip.html.hover="'Liked by ' + getNames(comment.likes)"
                      @click="likeComment(comment, itemId)"
                  >
                      <feather-icon
                          icon="HeartIcon"
                          size="18"
                          :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                      />
                      <span
                          v-if="comment.likes.length"
                          class="text-muted align-middle"
                      >
                    {{ comment.likes.length }}
                  </span>
                  </b-link>
                  <b-link
                      v-if="comment.user.hashid == userData.hashid"
                      class="text-primary btn btn-icon rounded-circle  p-50"
                      @click="editComments.pop(), editComments.push(comment.hashid)"
                  >
                      <feather-icon icon="EditIcon" size="18" />
                  </b-link>
                  <b-link
                      v-if="comment.user.hashid == userData.hashid"
                      class="text-danger btn btn-icon rounded-circle p-50"
                      @click="
                    deleteComment(comment.hashid, comment.post_hashid, itemId)
                  "
                  >
                      <feather-icon icon="TrashIcon" size="18" class="mr-lg-25" />
                  </b-link>
              </div> -->
            <!-- <div>
            <b-link class="nav-link">
              <feather-icon icon="CornerUpLeftIcon" size="18" class="mr-25" />
              Reply
            </b-link>
          </div> -->
          <!-- End Mobile Code -->
        </div>
        <div class="w-100 cmt-posts d-flex align-items-end justify-content-centent" 
        v-else="editComments.includes(comment.hashid)">
        <div
          class="
            border
            p-751
            commentBox
            round
            w-100
            mt-05
          "
        >
          <CommentBox
            placeholder="Share your thoughts..."
            className="w-100 comment-box edit-cmt pt-25"
            :id="'comment-field-' + comment.hashid"
            :value="comment.comment"
            :toolbar="false"
            :mentionList="mentionList"
          />
          <div class="cmd-editbtn">
            <b-button variant="outline-secondary" v-if="editComments.includes(comment.hashid)"
            class="blog-btn-block search-post m-0" @click="editComments.pop()">
            Cancel
            </b-button>
            <b-button variant="primary" @click="editComment(comment, itemId)" pill
            class="blog-btn-block search-post m-0" :disabled="commentSubmitClicked">
            Save
            </b-button>
          </div>
        </div>
        </div>
      </div>
     
      <div class="option-reply d-flex align-items-center w-100 pl-3">
            <b-row class="like-button mx-0 align-items-center">
              <div class="action-group d-flex align-items-center">
                <b-link class="d-flex align-items-center text-muted text-nowrap post-likes">
                  <div class="likes">
                    <!-- <span title="Like" class="active-like" @click="likeComment(comment, index,'like')">Likess</span> -->

                    <span 
                      title="Like" 
                      class="active-like" 
                      v-if="comment.youLiked && comment.likeType"
                  >
                      <!-- <b-img 
                          v-if="comment.likeType === 'like'" 
                          :src="require('@/assets/images/icons/like/like.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="likeComment(comment, index, 'like')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'clap'" 
                          :src="require('@/assets/images/icons/like/appreciate.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="likeComment(comment, index, 'clap')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'love'" 
                          :src="require('@/assets/images/icons/like/love.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="likeComment(comment, index, 'love')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'celebrate'" 
                          :src="require('@/assets/images/icons/like/celebrate.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="likeComment(comment, index, 'celebrate')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'idea'" 
                          :src="require('@/assets/images/icons/like/insightful.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="likeComment(comment, index,'idea')"
                      />
                      <b-img 
                          v-else-if="comment.likeType === 'smile'" 
                          :src="require('@/assets/images/icons/like/laugh.gif')" 
                          width="14" 
                          class="small-icon" 
                          @click="likeComment(comment, index,'smile')"
                      /> -->

                      <span 
                      v-if="comment.likeType === 'like'" 
                      title="Like" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment, index, 'like')" 
                  >
                      Like
                  </span>

                  <span 
                      v-if="comment.likeType === 'clap'" 
                      title="Appreciate" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment, index, 'clap')" 
                  >
                      Appreciate
                  </span>

                  <span 
                      v-if="comment.likeType === 'love'" 
                      title="Love" 
                      class="active-like" 
                      style="color: #D92D20"
                      @click="like(comment, index, 'love')" 
                  >
                      Love
                  </span>

                  <span 
                      v-if="comment.likeType === 'celebrate'" 
                      title="Celebrate" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment, index, 'celebrate')" 
                  >
                     Celebrate
                  </span>

                  <span 
                      v-if="comment.likeType === 'idea'" 
                      title="Insightful" 
                      class="active-like" 
                      style="color: #0E7090"
                      @click="like(comment, index, 'idea')" 
                  >
                  Insightful
                  </span>

                  <span 
                      v-if="comment.likeType === 'smile'" 
                      title="Laugh" 
                      class="active-like" 
                      style="color: #B54708"
                      @click="like(comment, index, 'smile')" 
                  >
                      Laugh
                  </span>
                  </span>

                  <span 
                      title="Like" 
                      class="active-like" 
                      @click="likeComment(comment, index, 'like')" 
                      v-else
                  >
                      Like
                  </span>
                    <div class="emoji-reactions">
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'like')" v-b-tooltip.hover.v-default title="Like">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/like.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'clap')" v-b-tooltip.hover.v-default title="Appreciate">
                        <b-img :src="require('@/assets/images/icons/like/appreciate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'love')" v-b-tooltip.hover.v-default title="Love">
                        <b-img :src="require('@/assets/images/icons/like/love.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/love.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'celebrate')" v-b-tooltip.hover.v-default title="Celebrate">
                        <b-img :src="require('@/assets/images/icons/like/celebrate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'idea')" v-b-tooltip.hover.v-default title="Insightful">
                        <b-img :src="require('@/assets/images/icons/like/insightful.svg')" width="22"  class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'smile')" v-b-tooltip.hover.v-default title="Laugh">
                        <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                    </div>
                  </div>
                  <div class="likes" v-if="comment.youLiked && comment.likeType">
                    <div class="emoji-reactions">
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'like')" v-b-tooltip.hover.v-default title="Like">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/like.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'clap')" v-b-tooltip.hover.v-default title="Appreciate">
                        <b-img :src="require('@/assets/images/icons/like/appreciate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'love')" v-b-tooltip.hover.v-default title="Love">
                        <b-img :src="require('@/assets/images/icons/like/love.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/love.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'celebrate')" v-b-tooltip.hover.v-default title="Celebrate">
                        <b-img :src="require('@/assets/images/icons/like/celebrate.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'idea')" v-b-tooltip.hover.v-default title="Insightful">
                        <b-img :src="require('@/assets/images/icons/like/insightful.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="likeComment(comment, index,'smile')" v-b-tooltip.hover.v-default title="Laugh">
                        <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="22" class="default-icon"></b-img>
                        <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="22" class="hover-icon"></b-img>
                      </div>
                    </div>
                  </div>

                  <!-- <span class="text-primary" v-if="comment.likes_count" v-b-modal="'show-likes-' + comment.hashid">{{
                    kFormatter(comment.likes_count) }}</span> -->
                </b-link>

                <b-modal :id="'show-likes-' + comment.hashid" hide-footer hide-header modal-class="modal-likes" class="reactions-popup" @show="groupCommentLikes(comment)" #default="{ hide }" centered>
                  <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="hide" />  
                  <!-- Static New Design -->
                    <b-tabs v-if="commentLikes && commentLikes.all && commentLikes.all.length" class="overall-reaction">
                      <b-tab id="user-reactions">
                          <template #title>
                            <span class="d-flex align-items-center"><span>All</span> <span class="count">({{commentLikes.all.length}})</span></span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="like in commentLikes.all">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img v-if="like.type == 'like'" :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'clap'" :src="require('@/assets/images/icons/like/appreciate.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'love'" :src="require('@/assets/images/icons/like/love.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'celebrate'" :src="require('@/assets/images/icons/like/celebrate.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'idea'" :src="require('@/assets/images/icons/like/insightful.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else-if="like.type == 'laugh'" :src="require('@/assets/images/icons/like/laugh.gif')" width="7" class="small-icon"></b-img>
                                    <b-img v-else :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ like.user.name }}
                                    </h6>
                                    <small>
                                      {{ like.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="like-reaction" v-if="commentLikes.like && commentLikes.like.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/like.gif')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.like.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="like in commentLikes.like">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/like.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ like.user.name }}
                                    </h6>
                                    <small>
                                      {{ like.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="appriceate-reaction" v-if="commentLikes.clap && commentLikes.clap.length" >
                        <template #title>
                          <span class="d-flex align-items-center">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.gif')
                              " width="20"></b-img>
                            <span class="count">{{commentLikes.clap.length}}</span>
                          </span>
                        </template>
                        <b-card-text>
                          <ul class="list-unstyled">
                            <li v-for="clap in commentLikes.clap">
                              <div class="d-flex align-items-center">
                                <div class="position-relative note-reaction">
                                  <b-avatar  target="_blank" :href="`/profile/${clap.user.hashid}`" :src="clap.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                  <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="7" class="small-icon"></b-img>
                                </div>
                                <div class="profile-user-info">
                                  <h6 class="mb-0">
                                    {{ clap.user.name }}
                                  </h6>
                                  <small>
                                    {{ clap.user.designation }}
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-tab>
                      <b-tab id="laugh-reaction" v-if="commentLikes.laugh && commentLikes.laugh.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/laugh.svg')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.laugh.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="laugh in commentLikes.laugh">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${laugh.user.hashid}`" :src="laugh.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ laugh.user.name }}
                                    </h6>
                                    <small>
                                      {{ laugh.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="love-reaction" v-if="commentLikes.love && commentLikes.love.length" >
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/love.gif')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.love.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="love in commentLikes.love">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${love.user.hashid}`" :src="love.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/love.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ love.user.name }}
                                    </h6>
                                    <small>
                                      {{ love.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                      <b-tab id="celebrate-reaction" v-if="commentLikes.celebrate && commentLikes.celebrate.length" >
                        <template #title>
                          <span class="d-flex align-items-center">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.gif')
                              " width="20"></b-img>
                            <span class="count">{{commentLikes.celebrate.length}}</span>
                          </span>
                        </template>
                        <b-card-text>
                          <ul class="list-unstyled">
                            <li v-for="celebrate in commentLikes.celebrate">
                              <div class="d-flex align-items-center">
                                <div class="position-relative note-reaction">
                                  <b-avatar  target="_blank" :href="`/profile/${celebrate.user.hashid}`" :src="celebrate.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                  <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="7" class="small-icon"></b-img>
                                </div>
                                <div class="profile-user-info">
                                  <h6 class="mb-0">
                                    {{ celebrate.user.name }}
                                  </h6>
                                  <small>
                                    {{ celebrate.user.designation }}
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-card-text>
                      </b-tab>
                      <b-tab id="insightful-reaction" v-if="commentLikes.idea && commentLikes.idea.length">
                          <template #title>
                            <span class="d-flex align-items-center">
                              <b-img :src="require('@/assets/images/icons/like/insightful.gif')
                                " width="20"></b-img>
                              <span class="count">{{commentLikes.idea.length}}</span>
                            </span>
                          </template>
                          <b-card-text>
                            <ul class="list-unstyled">
                              <li v-for="idea in commentLikes.idea">
                                <div class="d-flex align-items-center">
                                  <div class="position-relative note-reaction">
                                    <b-avatar  target="_blank" :href="`/profile/${idea.user.hashid}`" :src="idea.user.avatar" style="background: transparent" size="30" class="mr-75" />
                                    <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="7" class="small-icon"></b-img>
                                  </div>
                                  <div class="profile-user-info">
                                    <h6 class="mb-0">
                                      {{ idea.user.name }}
                                    </h6>
                                    <small>
                                      {{ idea.user.designation }}
                                    </small>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </b-card-text>
                      </b-tab>
                    </b-tabs>
                     <!-- End Static New Design -->
                    <!-- <b-list-group v-if="postLikes && postLikes.length">
                      <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                        class="d-flex align-items-center">
                        <b-avatar target="_blank" :href="`/profile/${like.user.hashid}`" :src="like.user.avatar" size="24" class="mr-1" />
                        <b-link
                          :to="{
                            name: 'employee-profile',
                            params: { id: like.user.hashid },
                          }"
                          class="font-weight-bold d-inline-block text-nowrap"
                          target="_blank"
                        >
                          {{ like.user.name }}
                        </b-link>
                        <div class="ml-auto" v-if="like.type == 'like'">
                          <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'clap'">
                          <b-img :src="require('@/assets/images/icons/like/appreciate.svg')
                            " width="24" title="Appreciate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'love'">
                          <b-img :src="require('@/assets/images/icons/like/love.svg')
                            " width="24" title="Love"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'celebrate'">
                          <b-img :src="require('@/assets/images/icons/like/celebrate.svg')
                            " width="24" title="Celebrate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'idea'">
                          <b-img :src="require('@/assets/images/icons/like/insightful.svg')
                            " width="24" title="Insightful"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'laugh'">
                          <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="24" title="Laugh"></b-img>
                        </div>
                      </b-list-group-item>
                    </b-list-group> -->
                    <b-card-text v-else-if="commentLikes && commentLikes.length == 0">
                      <div class="item error text-center my-2 empty-data">
                        <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                          style="background-color: transparent" />
                        <h4> OH NO!</h4>
                        <p class="d-block mb-1">No Records Found!</p>
                      </div>
                    </b-card-text>
                    <b-card-text v-else-if="commentLikes == null">
                      <div class="d-flex align-items-center">
                        <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                          style="background-color: transparent" />
                        <h4> Loading </h4>
                      </div>
                    </b-card-text>
                  </b-modal>
              </div>
              
            <div class="d-flex align-items-center" v-if="comment.likes && comment.likes.length">
              <!-- Likes Count -->
              <div class="likes mr-50">
                <div class="d-flex align-items-center gap-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none" class="mr-25"><circle cx="1.25" cy="2" r="1.25" fill="#667085"></circle></svg>
                  <b-avatar-group size="20px">
                      <b-avatar
                        v-for="(like, index) in getUniqueLikes(comment.likes).slice(0, 2)"
                        :key="index"
                        :src="getAvatarSrc(like.type)"
                        :title="capitalize(like.type)"
                        size="14"
                        variant="light"
                        v-b-modal="'show-likes-' + comment.hashid"
                      ></b-avatar>
                    </b-avatar-group>
                  <span
                      v-if="comment.likes_count > 0"
                      class="reactions-count ml-50"
                      v-b-modal="'show-likes-' + comment.hashid"
                    >
                     {{ comment.likes_count }}
                  </span>
                </div>
              </div>
              <!-- Replies-->
              <!-- <b-link class="nav-link reply-count" @click="getReplyComments(itemId); show=!show">
                <feather-icon v-if="!show && comment.reply_count" icon="PlusIcon" size="12" />
                <feather-icon v-if="show && comment.reply_count"  icon="MinusIcon" size="12" />
                {{ comment.reply_count }} Replies
              </b-link> -->
            </div>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="2" height="15" viewBox="0 0 2 15" fill="none" class="line-separate mr-50">
                <path d="M1 0.5L1 14.5" stroke="#98A2B3" stroke-width="0.5" stroke-linecap="round" />
              </svg>
            </span>
              <div class="reply-action d-flex align-items-center mt-0">
                <b-link class="btn btn-icon rounded-circle px-50 py-0 small" @click="addReply(comment, index)">
                  Reply
                </b-link>
              </div>
            </b-row>
          </div>

      
      </div>
        <!-- Reply and Like Section -->
        <div class="reply-cmd">
                <Replies
                v-if="comment.reply_count "
                  :itemId="comment.hashid"
                  :reply_count="comment.reply_count"
                  :mentionList="mentionList"
                />
              </div>
              <div
                v-if="comment_id && comment_id == comment.hashid"
                class="replyto-cmd pb-1 my-1 pl-3"
              >
              <div class="d-flex justify-content-between align-items-center">
                <!-- <div class="small">
                  Reply to <span class="font-weight-bolder">{{ reply_to }}</span>
                  </div> -->
                  <!-- <b-link
                    class="btn btn-icon rounded-circle p-50"
                    @click="removeReply"
                  >
                    <feather-icon icon="XIcon" size="18" 
                    v-b-tooltip.hover.v-default
                    title="Cancel"/>
                  </b-link> -->
                </div>
              <!--/ add comments -->
              <div  v-if="comment_id && comment_id == comment.hashid" class="w-100 cmt-posts d-flex align-items-end justify-content-center">
                <div class="w-100 border commentBox main-post" :class="{ 'focused': isReplyFocused }"  @mousedown="isReplyFocused = true" >
                  <CommentBox
                    placeholder="Enter Your Reply"
                    className="comment-box pt-25"
                    :id="'comment-field-' + comment.hashid"
                    :toolbar="false"
                    :mentionList="mentionList"
                    @update:value="handleReplyCommentUpdate" 
                  />
                  <b-button
                  variant="primary"
                  @click="addComment(comment)"
                  pill
                  class="blog-btn-block search-post m-0"
                  v-if="replyShow" 
                  :disabled="commentSubmitClicked"
                >
                Comment
                </b-button>
                </div>
              </div>
              </div>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Replies from "./Replies.vue";
import { kFormatter } from "@core/utils/filter";
import $ from "jquery";
Vue.use(VueCookies);

export default {
  name:'Replies',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    QuillInput,
    CommentBox,
    Replies
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    reply_count: {
      type: Number,
      required: true,
      default: 0,
    },
    mentionList: {
      required: true,
    },
  },

  data() {
    return {
      isBusy: false,
      data_local: {
        channel_type: "community",
      },
      customFields: [],
      data: [],
      replies: [],
      userData: this.$cookies.get("userData"),
      editComments: [],
      show:false,
      comment_id: null,
      comment_post_index:null,
      reply_to: null,
      commentSubmitClicked:false,
      commentLikes:null,
      isReplyFocused : false,
      replyShow : false
    };
  },
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarActive(val) {
      this.data_local = {
        channel_type: "community",
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
  },
  mounted() {},
  methods: {
    kFormatter,
    editComment(comment, post_id) {
      this.commentSubmitClicked=true;
      const self = this;
      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.commentSubmitClicked=false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.getReplyComments(post_id);
          this.commentSubmitClicked=false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked=false;
        });
      return true;
    },
    handleReplyCommentUpdate(val){
      this.replyShow = val;
      this.isReplyFocused = val
      if(!this.replyShow){
      this.isReplyFocused = false 
      }
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
groupCommentLikes(comments) {
  this.commentLikes = null;

  const types = ['like', 'clap', 'love', 'celebrate', 'idea', 'laugh'];

  const groupedReactions = types.reduce((acc, type) => {
    acc[type] = [];
    return acc;
  }, {});

  if (Array.isArray(comments.likes)) {
    comments.likes.forEach(reaction => {
      if (groupedReactions.hasOwnProperty(reaction.type)) {
        groupedReactions[reaction.type].push(reaction);
      }
    });
  } else {
    console.error("comments.likes is not an array:", comments.likes);
  }

  groupedReactions['all'] = comments.likes || [];

  this.commentLikes = groupedReactions;
  
  return groupedReactions;
},


    getUniqueLikes(likes) {
      
      const seenTypes = new Set();
      return likes.filter(like => {
        if (!seenTypes.has(like.type)) {
          seenTypes.add(like.type);
          return true;
        }
        return false;
      });
    },
    getAvatarSrc(type) {
      const icons = {
        like: require('@/assets/images/icons/like/like.svg'),
        clap: require('@/assets/images/icons/like/appreciate.svg'),
        love: require('@/assets/images/icons/like/love.svg'),
        celebrate: require('@/assets/images/icons/like/celebrate.svg'),
        idea: require('@/assets/images/icons/like/insightful.svg'),
        smile: require('@/assets/images/icons/like/laugh.svg')
      };
      return icons[type] || '';
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    deleteComment(commentId, postId, itemId) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          this.getReplyComments(itemId);
        });
    },
    getReplyComments(comment_id,liked = false) {
      const self = this;
      this.isBusy = true;
      let data = {};

      data.url = "/comments/" + comment_id + "/replies";
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.replies = res.data.data;
          this.isBusy = false;
          if(!liked){
            this.show = !this.show;
          }
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          self.channel_type_select = res.data.channel_type_select;
          self.identity_type_select = res.data.identity_type_select;
          self.feedback_type_select = res.data.feedback_type_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })

        });
    },
    resetFormData() {
      console.log("reset");
      // this.data_local = {
      //   priority: "medium",
      //   status: "open",
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      //   this.data_local.category_id = this.itemData.support_category.hashid;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("post-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }
      let data = {};
      data.url = "posts";
      // data.params = formData;
      data.params = self.data_local;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Post Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("post-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "posts/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Post Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-posts/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
          this.data_local.category_id = res.data.data.support_category.hashid;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addReply(data, index) {
      const self = this;
      self.comment_id = data.hashid;
      self.reply_to = data.user.name;
      self.comment_post_index = index;
      // document.getElementById("comment-field-" + data.hashid).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = null;
    },
    likeComment(comment, index, type) {
      if (comment.youLiked && comment.likeType == type) {
        this.$http.delete(`/comments/${comment.hashid}/like`).then((res) => {
          // this.$parent.updatePostArray(res.data.data, index);
          comment.youLiked = false;
          comment.likeType = false;
          this.$set(this.replies, index, comment);
        });
      } else {
        this.$http.post(`/comments/${comment.hashid}/like/${type}`).then((res) => {
          comment.likeType = type;
          comment.youLiked = true;
          this.$set(this.replies, index,comment);
        });
      }
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          //this.$parent.updatePostArray(res.data.data, index);
          this.$forceUpdate(); 
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });

          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Comment deleted successfully',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 

        });
    },
    addComment(data) {
      this.commentSubmitClicked =true;
      const self = this;
      const comment = document.getElementById(`comment-field-${data.hashid}`).value;
      if (comment === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Comment adding Failed",
        //     icon: "BellIcon",
        //     text: "Can't post empty comment",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Can't post empty comment",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: data.hashid,
      };
      this.$http
        .post(`/posts/${data.post_hashid}/comments`, params)
        .then((res) => {
          self.reply_to = null;
          self.comment_id = null;
          document.getElementById(`comment-field-${data.hashid}`).value = "";
          $(".ql-editor").html("");
          this.getReplyComments(data.hashid);
          this.$forceUpdate();
          this.commentSubmitClicked = false; 
        })
        .catch((error) => {
          // Do something on error
          console.log(error);
          this.commentSubmitClicked = false;
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    getMosReactions(likes){
      var reactions = {
        'like' : 0,
        'love' : 0,
        'celebrate' : 0,
        'clap' : 0,
        'laugh' : 0,
        'idea':0
      };

      likes.forEach(like => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter(key => reactions[key] !== 0);

      return top3;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
